import Header from "./components/header";
import Contact from "./components/contact";
import Footer from "./components/footer";
import './App.scss';

function App() {
  return (  
    <div>
App    </div>
  );
}

export default App;
