import React from 'react'

const Header = () => {
  return (
    <>
    <h1>Header</h1>
    </>
  )
}

export default Header